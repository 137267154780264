import React from "react"

import Layout from "../components/layout"
import Article from "../components/article"
import SEO from "../components/seo"
import Contact from "../components/Contact"

const ContactPage = () => (
  <Layout>
    <SEO title="Contact" />
    <Article>
      <Contact />
    </Article>
  </Layout>
)

export default ContactPage
