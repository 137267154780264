import React from "react"

const Contact = () => (
  <>
    <p>To get in contact:</p>
    <ul>
      <li>
        <a href="mailto:dom@domdefelice.net">
          <strong>E-mail</strong>
        </a>
      </li>
      <li>
        <a href="https://www.linkedin.com/in/domenico-de-felice-88a71073/">
          <strong>LinkedIn</strong>
        </a>
      </li>
      <li>
        <a href="https://twitter.com/DeFeliceDom">
          <strong>Twitter</strong>
        </a>
      </li>
      <li>
        <a href="https://www.facebook.com/lambda.squared.dom/">
          <strong>Lambda squared</strong> on Facebook
        </a>
      </li>
      <li>
        <strong>
          <a href="https://www.facebook.com/Dome.De.Felice">Facebook</a>
        </strong>
      </li>
    </ul>
  </>
)

export default Contact
